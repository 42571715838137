import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"
import Content from "../components/content"

const AboutUsPage = () => (
  <Layout>
    <SEO title="O nas" />
    <Title title="O nas" />
    <Content>
      <p><strong>Kiedy w 1997 roku na początku działalność K.B. Volt dostaliśmy propozycję świadczenia usług dla kopalni KWK Budryk, powstało pytanie: czy damy radę? Zarówno wtedy, jak i teraz był to zakład słynący z innowacji i dynamicznie rozwijający się, więc wymagający od swoich kontrahentów najwyższej jakości usług. Sprawę przemyśleliśmy, rozpisaliśmy nasze słabe i mocne strony i zdecydowaliśmy: wchodzimy w to. Warto było podjąć to ryzyko, gdyż nasza współpraca trwa nieprzerwanie od wielu lat. Fakt, że obsługujemy cały ruch elektryczny, tak dużego zakładu przemysłowego świadczy o zaufaniu do specjalistów, których zatrudniamy i jakości naszych usług.</strong></p>
      <h2>Instalacje elektryczne w kopalniach</h2>
      <p><strong>KWK Budryk</strong> to klient potrzebujący szerokiej gamy usług. Dotyczy to m.in. innowacyjnych projektów takich jak budowa generatora prądu z metanu, którą z sukcesem przeprowadziliśmy. Istotna jest także nasza obsługa stacji odmetanowania, co jest kluczowe dla bezpieczeństwa każdej kopalni. Niewątpliwie ciekawe okazały się także <strong>remonty maszyn górniczych</strong>. Do wielu z tych urządzeń trudno jest dostać czasem części zamienne, a niekiedy ich cena jest ogromna. Również czas oczekiwania na komponenty jest długi, a przestoje w pracy tak dużych zakładów nie wchodzą w grę. Dlatego<strong> skuteczna naprawa maszyn górniczych jest często w stanie przynieść duże oszczędności kopalni</strong> i znacznie zwiększyć jej wydajność wydobycia. Dla KWK Budryk świadczymy także kompleksowe usługi, którymi są modernizacja oświetlenia, drobne naprawy i cykliczne przeglądy instalacji elektrycznych. <strong>K.B. Volt</strong> zaopatruje również kopalnię w kable elektryczne, urządzenia sterownicze, zabezpieczenia górnicze itp.</p>
      <p><strong>KWK Wujek</strong> to nasz kolejny klient z branży wydobywczej, który dostarczył nam wymagających zleceń. To dla tego historycznego zakładu przeprowadziliśmy budowę złącza obrotowego na zbiorniku flockowania. Istotna również była <strong>kompleksowa modernizacja instalacji elektrycznej</strong> na zbiorniku węgla surowego. Był to duży projekt wymagający pracy w potężnej przestrzeni, co wynika z dużej objętości tego typu konstrukcji.</p>
      <h2>Fotowoltaika dla domu – bezpieczna energooszczędność</h2>
      <p>Ten dynamicznie rozwijający się rynek zasługuje na najlepsze usługi, a takich <strong>K.B. Volt</strong> dostarcza. Wybierając fotowolatikę do domu, zyskasz niższe rachunki za prąd, wydajne źródło energii elektrycznej i przysłużysz się środowisku. Jest to zdecydowanie lepsze rozwiązanie, które uzupełni, a z czasem zastępuje zasilanie z elektrowni. Dzięki temu <strong>zyskasz niezależność energetyczną</strong>, która owocuje wieloletnią spokojną głową i zdecydowanymi oszczędnościami</p>
      <h2>Fotowoltaika dla firm</h2>
      <p>Rozwiązania stosowane w domach wykorzystujemy na większą skalę w firmach. Tutaj możesz liczyć na dziesiątki paneli fotowoltaicznych znajdujących się na dachu magazynu, hali produkcyjnej, czy firmy usługowej. Bardzo dobrym rozwiązaniem okazuje się ich montaż obok budynku na wolnej i nasłonecznionej przestrzeni, która nie stawia żadnych ograniczeń. Dobrze przygotowany projekt instalacji elektrycznej opierającej się na fotowoltaice dostarczy energii czystej, ekologicznej i darmowej.<strong> Inwestycja w fotowoltaikę zwraca się szybko</strong>, a panele zachowują swoją moc przez naprawdę wiele lat. Dodatkowo system akumulatorów gromadzących prąd sprawi, że w sytuacjach awaryjnych zakład może nadal funkcjonować. Dzięki temu czasowe blackouty, czy letnie przestoje energetyczne nie stanowią większych problemów.</p>
      <h2>Obsługa systemów elektrycznych</h2>
      <p>Zlecenia dla wspólnot mieszkaniowych na terenie miasta Zabrze były poszerzeniem zakresu usług w stronę klienta de facto detalicznego. Ten segment rynku zmaga się z diametralnie innymi problemami niż przemysł górniczy. Jednak wejście w przestrzeń, w której ostatecznym odbiorcą naszych usług byli mieszkańcy zabrzańskich osiedli okazał się szansą na rozwój <strong>K.B. Volt.</strong> Łącząc te dwa światy – przemysłowy i odbiorcy indywidualnego energii elektrycznej, zyskaliśmy szansę na <strong>dogłębne zrozumienie potrzeb różnego rodzaju klientów.</strong></p>
      <h2>Oświetlenie przemysłowe</h2>
      <p><strong>PKP Cargo</strong> szukało firmy, która przeprowadziłaby dla nich remont oświetlenia hali naprawczej. Kwestia dotyczyła przejścia z dawnego systemu energochłonnego na nowe <strong>oświetlenie przemysłowe LED</strong>. Jest to zdecydowanie lepsza alternatywa dla kosztownych rozwiązań starej daty. Dla funkcjonowania dużych obiektów, którymi są kolejowe hale naprawcze, takie rozwiązanie okazuje się istotnie ograniczać koszta przyszłych rozliczeń z elektrowniami. Dla PKP Cargo przygotowaliśmy oświetlenie awaryjne, oświetlenie ewakuacyjne, a także oświetlenie kanałów naprawczych i oświetlenie stanowisk pracy. Wtedy też przeprowadziliśmy precyzyjne pomiary natężenia światła, które dla jakości wykonywanej pracy są niezwykle ważne. Pozwoliło nam to dobrać takie oświetlenie przemysłowe LED, by specjalistom PKP Cargo pracowało się jak najlepiej. Dotyczy to zarówno ich komfortu, jak i bezpieczeństwa.</p>
      <p><strong>Modernizacja oświetlenia przemysłowego</strong> dla CEZ Skawina, której z chęcią się podjęliśmy, dotyczyła remontu oświetlenia nastawni. Te urządzenia pracując często w nocy i w trudnych warunkach atmosferycznych potrzebują oświetlenia przemysłowego z najwyższej półki. Jednak same lampy nie robią roboty, więc by je odpowiednio zamontować, przygotowaliśmy szczegółowe projekty instalacji elektrycznych. Pomocne okazały się tutaj pomiary natężenia światła z uwzględnieniem zmiennej aury i różnych pór dnia. Dla <strong>CEZ Skawina</strong> przeprowadziliśmy również modernizację oświetlenia dyspozytorni, gdzie jakość pracy musi być wysoka.</p>
      <h2>Roboty przemysłowe i instalacje PPOŻ</h2>
      <p>Kiedy <strong>Volkswagen</strong> instalował w swojej fabryce w wielkopolskiej Wrześni <strong>roboty przemysłowe</strong> KUKA, realizowaliśmy dla niemieckiego koncernu to zlecenie. Dotyczyło to wykonania projektów instalacji elektrycznej, jak i samego podłączenia nowoczesnych maszyn. Volkswagen potrzebował również <strong>wykonania instalacji PPOŻ, instalacji awaryjnej, instalacji ewakuacyjnych i sygnalizacyjnych</strong>. Zlecenie to zrealizowaliśmy, co pozwoliło nam zdobyć spore doświadczenie w dziedzinie wdrażania systemów robotyki w przemyśle samochodowym.</p>
      <h3>To nie wszystko...</h3>
      <p>Realizujemy także <strong>projekty instalacji elektrycznych</strong> dla przemysłu, domów, czy obiektów użyteczności elektrycznej. W naszym asortymencie doświadczeń pojawia się także możliwość tworzenia <strong>car portów</strong>. Te w dobie rozwijającego się rynku samochodów elektrycznych stają się coraz bardziej popularne.</p>
      <p>Do każdego projektu podchodzimy kompleksowo. Zaczynamy od poznania potrzeb klienta i specyfiki jego realiów. Następnie przychodzi czas na opracowanie koncepcji, szukanie najlepszych możliwych komponentów i dopasowanie ich do istniejących już systemów elektrycznych, czy tworzenie tych nowych.<strong> K.B. Volt</strong> świadczy także wsparcie w zakresie wykonawstwa, które najlepiej realizować z osobami tworzącymi projekt. Zadbamy także o Twój system elektryczny po zleceniu, zapewniając <strong>wsparcie serwisowe i modernizacyjne.</strong></p>
    </Content>
  </Layout>
)

export default AboutUsPage
